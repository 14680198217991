import './App.css';
import {
  Routes,
  Route,
  RouterProvider,
  createBrowserRouter
} from "react-router-dom";

import PrivateRoutes from './components/private';
import Devices from './pages/devices';
import LoginPage from './pages/public/login';
import RegisterPage from './pages/public/signup';
import ForgotPage from './pages/public/forgot';
import ResetPasswordPage from './pages/public/reset';
import DeviceDetail from './pages/devices/detail';
import Account from './pages/account/settings';
import Sources from './pages/sources';
import Profiles from './pages/profiles';
import ProfileSettings from './pages/profiles/settings';
import Checkout from './pages/account/checkout';
import { AuthProvider } from './services/AuthProvider';
import { Crisp } from "crisp-sdk-web";
import { useEffect } from 'react';
import MqttClient from './services/ws/MqttClient';
import Users from './pages/users';
import * as Sentry from "@sentry/react";

const router = createBrowserRouter([
  { path: "*", Component: Root },
]);

function Root() {
  return (
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/forgot-password" element={<ForgotPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route element={<PrivateRoutes/>}>
          <Route path="/users" element={<Users />} />
          <Route path="/profiles" element={<Profiles />} />
          <Route path="/profiles/:profileId/settings" element={<ProfileSettings />} />
          <Route path="/sources" element={<Sources />} />
          <Route path="/account" element={<Account />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/devices/:deviceId" element={<DeviceDetail />} />
        </Route>
      </Routes>
  );
}

function App() {
  const cc = process.env.REACT_APP_CHAT_KEY;
  useEffect(() => {
    if (cc && cc !== '') {
      Crisp.configure(cc);
    }

    // Sentry
    Sentry.init({
      dsn: "https://87beb97ec60a09c01bbb8f3b44ff20c6@o4508468668989440.ingest.us.sentry.io/4508468671676416",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/app.zafron\.dev\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }, [cc]);
  

  return (
    <AuthProvider>
      <MqttClient>
        <RouterProvider router={router} />
      </MqttClient>
    </AuthProvider>
  )
}

export default App;
