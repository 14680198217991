import React, { useState, useEffect } from 'react';
import { Card, Title, Button, Flex, Text, Divider } from '@tremor/react';
import { useNavigate } from "react-router-dom";

import Nav from '../../components/nav';
import {
  Table,
  TableHead,
  TableHeaderCell,
  TableBody,
  TableRow,
  TableCell,
} from "@tremor/react";

import * as userAPI from '../../services/user';
import { toFriendlyTime } from '../../services/utils';
import { useAuth } from '../../services/AuthProvider';

export default function Users() {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    try {
      const response = await userAPI.getUsers(page, limit);
      console.log(response);
      setUsers(response.data);
      // setSources(response.data);
      setTotal(response.total);
    } catch (error) {
      console.error(error);
    }
  }

  const impersonateUser = async (user) => {
    try {
      const userPersona = await userAPI.impersonate(user._id);
      // Handle successful impersonation, e.g., redirect to the impersonated user's dashboard
      login(user, userPersona.token);
      navigate('/devices');
    } catch (error) {
      console.error(error);
    }
  }

  // Read operation
  useEffect(() => {
    getUsers();
  }, [page, limit]);

  return (
    <div>
    <Nav />
    <main className="p-4 md:p-10 mx-auto max-w-7xl">
      <Flex>
        <div>
          <Title>Users</Title>
          <Text>
            List of users
          </Text>
        </div>
      </Flex>
      <Divider />
      <Card className="mt-6">
        <Table>
        <TableHead>
          <TableRow>
            <TableHeaderCell>Name</TableHeaderCell>
            <TableHeaderCell>Email</TableHeaderCell>
            <TableHeaderCell>Last login</TableHeaderCell>
            <TableHeaderCell>Actions</TableHeaderCell>
            <TableHeaderCell></TableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user._id}>
              <TableCell>{user.name}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>
                {toFriendlyTime(user.lastLogin)}
              </TableCell>
              <TableCell>
                <Button variant="secondary" size="sm" onClick={()=> impersonateUser(user)}>Impersonate</Button></TableCell>
              <TableCell>
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </Card>
  </main>
  </div>
  );
};